<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="addPhoto">
    <div>
      <div>
        <vs-button style="float: right; margin-right: 50px;" color="primary" type="filled" v-on:click="goToList()" >목록</vs-button>
        <vs-button :disabled="!(uploadPercentage >= 100)" style="float: right; margin-right: 30px;" color="primary" type="filled" v-on:click="setPhoto(writePostVar)" >저장</vs-button>
      </div>
      <div id="file-drag-drop" class="flex flex-wrap flex-grow" style="margin-left: 50px;">
      <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <!-- <div class="common-contents">
          공통 제목 -->
          <!-- <ckeditor class="photo-subject" :editor="editor" v-model="subject" :config="editorConfig"></ckeditor> -->
          <!-- <vs-textarea class="photo-subject" v-model="subject" rows="2"/>
          공통 내용 -->
          <!-- <ckeditor class="photo-contents" :editor="editor" v-model="contents" :config="editorConfig"></ckeditor> -->
          <!-- <vs-textarea class="photo-contents" v-model="contents" rows="5"/>
          <vs-button style="float: right; margin-right: 50px;" color="primary" type="filled" v-on:click="setCommonText" >공통내용 적용</vs-button>
        </div> -->
        <form class="drag-drop" ref="fileform">
          <label class="drop-files"><p style="width: 100%; height: 100%;">여기를 클릭하거나 파일을 드래그해서 넣어주세요</p>
          <input multiple type="file" @change="addFile"></label>
          <progress max="100" :value.prop="uploadPercentage"></progress>
        </form>
      </div>

      <div class="flex flex-wrap flex-grow">
        <div class="photo-list" :data="photo" :key="index" v-for="(photo, index) in writePostVar.photos">
        <div style="padding: 10px">
          <toggle-button color="rgba(115, 103, 240, 1)" width="110"
            v-model="photo.sendNewsList" 
            :labels="{checked: '뉴스리스트 전송', unchecked: '뉴스리스트 전송'}"/>
        <!-- <vs-switch v-model="photo.sendNewsList">
          <span slot="on">뉴스리스트 전송</span>
          <span slot="off">뉴스리스트 전송</span>
        </vs-switch> -->
        </div>
          <img :src="makeImageLink(photo.mimg)" style="max-width: 250px; height: 150px;" />
        <!-- <ckeditor class="photo-subject" :editor="editor" v-model="photo.subject" :config="editorConfig"></ckeditor> -->
        <vs-textarea class="photo-subject" v-model="photo.subject" rows="2"/>
        <!-- <ckeditor class="photo-contents" :editor="editor" v-model="photo.contents" :config="editorConfig"></ckeditor> -->
        <vs-textarea class="photo-contents" v-model="photo.contents" rows="5"/>
          <!-- <textarea v-model="photo['subject']" name="subject" style="width:244px;color:#000f75;font-size:12px;font-weight:bold" rows="2" />
          <textarea v-model="photo['contents']" name="contents" style="width:244px;font-size:12px;" rows="5" /> -->
          <vs-button style="margin-left: 2px;margin-right: 2px;" size="small" color="danger" @click="removeFile(index)">포토삭제</vs-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import classicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios'
export default {
  components: {
  },
  data() {
    return {
      switch1: false,
      editor: classicEditor,
      editorConfig: {
        toolbar: {
            items: [],
        }
      },
      currentx: 1,
      numOfPosts: 0,
      numOfPages: 0,
      writePostVar:{
        writerName: '',
        writerIdx: '',
        photos: [],
      },
      files: [],
      subject: '<포토>',
      contents: 'ⓒ데일리안 ',
      dragAndDropCapable: true,
      uploadPercentage: 0,
    }
  },
  computed: {
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    setCommonText(){
      for(let photo of this.writePostVar.photos){
        photo.subject = this.subject;
        photo.contents = this.contents;
      }
    },
    setPhoto(data){
      
      // for(var idx in data.photos) {

      //   console.log(`idx(${idx}):`, data.photos[idx].sendNewsList)
      // }

      console.log('setPhoto data )', data)
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/photo/setPhoto', data)
        .then(function (response) {
          console.log(response);
          thisIns.$vs.loading.close();
          thisIns.$router.push({path: '/photoDB'});
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    goToList(){
      this.$router.push({path: '/photoDB'});
    },
    makeImageLink(filename){
      let imgPath = "/api/photo/viewImages?filename=" + filename;
      return imgPath;
    },
    removeFile(index){
      this.files.splice(index,1);
      this.writePostVar.photos.splice(index,1);
      if(this.files.length == 0) this.uploadPercentage = 0;
      // this.getImagePreviews();
    },
    addFile(e){
      // console.log(e.target.files, e.target.files[0]);
      for( let i = 0; i < e.target.files.length; i++ ){
        this.files.push( e.target.files[i] );
      }
      this.submitFiles(e.target.files);
    },
    /*
      Gets the image preview for the file.
    */
    getImagePreviews(){
      /*
        Iterate over all of the files and generate an image preview for each one.
      */
      for( let i = 0; i < this.files.length; i++ ){
        /*
          Ensure the file is an image file
        */
        if ( /\.(jpe?g|png|gif)$/i.test( this.files[i].name ) && this.files[i].size) {
          /*
            Create a new FileReader object
          */
          let reader = new FileReader();

          /*
            Add an event listener for when the file has been loaded
            to update the src on the file preview.
          */
          reader.addEventListener("load", function(){
            // console.log(this.$refs['preview'+parseInt( i )]);
            this.$refs['preview'+parseInt( i )][0].src = reader.result;
          }.bind(this), false);

          /*
            Read the data for the file in through the reader. When it has
            been loaded, we listen to the event propagated and set the image
            src to what was loaded from the reader.
          */
          reader.readAsDataURL( this.files[i] );
        }else{
          /*
            We do the next tick so the reference is bound and we can access it.
          */
          this.$nextTick(function(){
            this.$refs['preview'+parseInt( i )][0].src = '';
          });
        }
      }
    },
      /*
        Determines if the drag and drop functionality is in the
        window
      */
      determineDragAndDropCapable(){
        /*
          Create a test element to see if certain events
          are present that let us do drag and drop.
        */
        var div = document.createElement('div');

        /*
          Check to see if the `draggable` event is in the element
          or the `ondragstart` and `ondrop` events are in the element. If
          they are, then we have what we need for dragging and dropping files.

          We also check to see if the window has `FormData` and `FileReader` objects
          present so we can do our AJAX uploading
        */
        return ( ( 'draggable' in div )
                || ( 'ondragstart' in div && 'ondrop' in div ) )
                && 'FormData' in window
                && 'FileReader' in window;
      },

      /*
        Submits the files to the server
      */
      submitFiles(files){
        /*
          Initialize the form data
        */
        let formData = new FormData();
        let thisIns = this;

        /*
          Iteate over any file sent over appending the files
          to the form data.
        */
        for( var i = 0; i < files.length; i++ ){
          let file = files[i];
          formData.append('file', file);
        }
         formData.append('name', this.writePostVar.writerName);
         formData.append('tail', this.$store.state.AppActiveUser.tail);
        /*
          Make the request to the POST /file-drag-drop-instant URL
        */
        axios.post( '/api/photo/uploadFile',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }.bind(this)
          }
        ).then(function(response){
          console.log('SUCCESS!!', response);
          if(response.data.fileName.length){
            for(var i in response.data.fileName){
              thisIns.writePostVar.photos.push({
                  'mimg': response.data.fileName[i],
                  'subject': response.data.title[i] || thisIns.subject,
                  'contents': response.data.description[i] || thisIns.contents,
                });
            }
          }
          // thisIns.getImagePreviews();
        })
        .catch(function(response){
          console.log('FAILURE!!', response);
        });
      },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    // console.log(Array.from( this.editor.ui.componentFactory.names()))
    this.writePostVar.writerName = this.$store.state.AppActiveUser.name;
    this.writePostVar.writerIdx = this.$store.state.AppActiveUser.id;
    this.contents = 'ⓒ데일리안 ' + this.writePostVar.writerName + ' ' + this.$store.state.AppActiveUser.tail;
  },
  mounted() {
    this.isMounted = true;
          /*
        Determine if drag and drop functionality is capable in the browser
      */
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
      if( this.dragAndDropCapable ){
        /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        /*
          Add an event listener for drop to the form
        */
        this.$refs.fileform.addEventListener('drop', function(e){
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          for( let i = 0; i < e.dataTransfer.files.length; i++ ){
            this.files.push( e.dataTransfer.files[i] );
          }
          console.log(e.dataTransfer.files);
          this.submitFiles(e.dataTransfer.files);
        }.bind(this));
      }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/upload.scss";
</style>